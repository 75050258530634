import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button } from '@mui/material'; // Import Button
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getSearchedProducts } from '../../../redux/userHandle';
import { useLocation, useNavigate } from 'react-router-dom';

// Styled Button
const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#013220', // Change this to your desired button color
    color: '#ffffff',
    borderRadius: '8px',
    padding: '10px 20px',
    textTransform: 'none',
    transition: 'background-color 0.3s, transform 0.2s',
    '&:hover': {
        backgroundColor: '#008000',
        transform: 'scale(1.05)',
    },
}));

const ProductsMenu = ({ dropName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { productData, loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getProducts());
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const uniqueItems = productData?.filter((data, index, self) => {
        return dropName === "Explore Categories"
            ? self.findIndex((item) => item.category === data.category) === index
            : self.findIndex((item) => item.subcategory === data.subcategory) === index;
    });

    const catHandler = (key) => {
        setAnchorEl(null);
        if (dropName === "Explore Categories") {
            dispatch(getSearchedProducts("searchProductbyCategory", key));
        } else {
            dispatch(getSearchedProducts("searchProductbySubCategory", key));
        }

        if (location.pathname !== "/ProductSearch") {
            navigate("/ProductSearch");
        }
    };

    return (
        <div style={{ marginLeft: "2rem" }}>
            <StyledButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleClick}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
                {dropName}
            </StyledButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {loading ? (
                    <CircularProgress /> // Show loader if data is loading
                ) : (
                    uniqueItems?.map((data) => (
                        <MenuItem
                            onClick={() => catHandler(dropName === "Explore Categories" ? data.category : data.subcategory)}
                            key={data._id}
                        >
                            {dropName === "Explore Categories" ? data.category : data.subcategory}
                        </MenuItem>
                    ))
                )}
            </StyledMenu>
        </div>
    );
};

export default ProductsMenu;

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
