import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Paper,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddNews = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const categories = [
    "Admissions",
    "Exams & Results",
    "Conferences & Events",
    "Book Reviews"
  ];

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    setImageUrl(""); // Clear the URL input if a file is selected
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category", category);
    if (image) {
      formData.append("image", image); // Add image file to form data
    } else if (imageUrl) {
      formData.append("image", imageUrl); // Add image URL to form data
    }

    fetch("https://backend.alamkitab.com/api/news", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        setTitle("");
        setDescription("");
        setCategory("");
        setImage(null);
        setImageUrl("");
        setSnackbarMessage("News added successfully!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add news. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            marginBottom: 3,
            fontWeight: 'bold'
          }}
        >
          Add News
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: '100%' }}
        >
          <TextField
            fullWidth
            margin="normal"
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <Typography variant="subtitle1" sx={{ marginTop: 2, fontWeight: 'bold' }}>
            Description
          </Typography>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            style={{ height: '200px', marginBottom: '16px' }}
          />

          <TextField
            select
            fullWidth
            margin="normal"
            label="Category"
            variant="outlined"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <Typography
            variant="subtitle1"
            sx={{ marginTop: 2 }}
          >
            Upload an Image or Provide Image URL
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            label="Image URL"
            variant="outlined"
            value={imageUrl}
            onChange={(e) => {
              setImageUrl(e.target.value);
              setImage(null); // Clear the file input if URL is provided
            }}
          />

          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ marginTop: 1 }}
          >
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>

          {image && (
            <Typography
              variant="body2"
              sx={{ marginTop: 1 }}
            >
              Selected File: {image.name}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              marginTop: 2,
              padding: 1.5
            }}
            disabled={!title || !description || !category || (!image && !imageUrl)}
          >
            Add News
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddNews;
