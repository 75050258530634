import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './userSlice';
import { promoReducer } from './promoReducer';
import productHistoryReducer from './slices/printHistorySlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        promo: promoReducer,
        productHistory: productHistoryReducer,
    }
});

export default store;