import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./App.jsx";
import './index.css'
import { Provider } from 'react-redux';
import store from './redux/store.js';

const theme = createTheme({
    palette: {
        primary: {
            main: "#006001", 
            dark: "#004d00",
        },
        secondary: {
            main: "#f50057", 
            dark: "#ab003c", 
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>,
    document.getElementById("root")
);

