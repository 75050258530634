// productHistorySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunk to fetch product history
export const fetchProductHistory = createAsyncThunk(
  'productHistory/fetchProductHistory',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/print-requests/${id}`);
      return response.data; // API response data
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch data');
    }
  }
);

// Redux slice
const productHistorySlice = createSlice({
  name: 'productHistory',
  initialState: {
    productHistory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.productHistory = action.payload;
      })
      .addCase(fetchProductHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default productHistorySlice.reducer;
