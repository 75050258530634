// old

import axios from 'axios';
import {
    authRequest,
    authSuccess,
    authFailed,
    authError,
    stuffAdded,
    getDeleteSuccess,
    getRequest,
    getFailed,
    getError,
    productSuccess,
    productDetailsSuccess,
    getProductDetailsFailed,
    getProductsFailed,
    setFilteredProducts,
    getSearchFailed,
    sellerProductSuccess,
    getSellerProductsFailed,
    stuffUpdated,
    updateFailed,
    getCustomersListFailed,
    customersListSuccess,
    getSpecificProductsFailed,
    specificProductSuccess,
    updateCurrentUser,
} from './userSlice.js';

export const authUser = (fields, role, mode) => async (dispatch) => {
    dispatch(authRequest());

    try {
        const result = await axios.post(`https://backend.alamkitab.com/${role}${mode}`, fields, {
            headers: { 'Content-Type': 'application/json' },
        });
        if (result.data.role) {
            dispatch(authSuccess(result.data));
        } else {
            dispatch(authFailed(result.data.message));
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || 'Unknown error occurred';
        const statusCode = error.response?.status || 500;
        dispatch(authError({ message: errorMessage, status: statusCode }));
    }
};

// export const addStuff = (address, fields) => async (dispatch) => {
//     dispatch(authRequest());

//     try {
//         const result = await axios.post(`https://backend.alamkitab.com/${address}`, fields, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (result.data.message) {
//             dispatch(authFailed(result.data.message));
//         } else {
//             dispatch(stuffAdded());
//         }
//     } catch (error) {
//         dispatch(authError(error));
//     }
// };
export const addStuff = (address, fields) => async (dispatch) => {
    dispatch(authRequest());

    try {
        const isFormData = fields instanceof FormData; // Check if `fields` is FormData

        const result = await axios.post(`https://backend.alamkitab.com/${address}`, fields, {
            headers: {
                'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
            },
        });

        // Check if the result contains an error message
        console.log(`https://backend.alamkitab.com/${address}`);

        if (result.data.message) {
            dispatch(authFailed(result.data.message)); // Dispatch authFailed action with error message
        } else {
            dispatch(stuffAdded()); // Dispatch stuffAdded action if successful
        }
    } catch (error) {
        // Handle the error explicitly and extract relevant information
        const errorMessage = error.response?.data?.message || 'Unknown error occurred';
        const statusCode = error.response?.status || 500;
        dispatch(authError({ message: errorMessage, status: statusCode })); // Dispatch authError action with error data
    }
};
export const applyPromoCode = (code) => (dispatch) => {
    const promoCodes = [
        { code: 'DISCOUNT10', discount: 10 },
        { code: 'DISCOUNT20', discount: 20 },
    ];
    const matchedPromo = promoCodes.find((promo) => promo.code === code);

    if (matchedPromo) {
        dispatch({
            type: 'APPLY_PROMO_CODE',
            payload: matchedPromo,
        });
    } else {
        alert('Invalid promo code');
    }
};

// ye wala new hai
// Update your Redux thunk action creator addStuff to handle errors more explicitly
// export const addStuff = (address, fields) => async (dispatch) => {
//     dispatch(authRequest());

//     try {
//         // console.log(process.env.REACT_APP_BASE_URL)
//         const result = await axios.post(`https://backend.alamkitab.com/${address}`, fields, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         // Check if the result contains an error message
//         if (result.data.message) {
//             dispatch(authFailed(result.data.message)); // Dispatch authFailed action with error message
//         } else {
//             dispatch(stuffAdded()); // Dispatch stuffAdded action if successful
//         }
//     } catch (error) {
//         // Handle the error explicitly and extract relevant information
//         const errorMessage = error.response?.data?.message || 'Unknown error occurred';
//         const statusCode = error.response?.status || 500;
//         dispatch(authError({ message: errorMessage, status: statusCode })); // Dispatch authError action with error data
//     }
// };

// In redux/userHandle.js

// Action to mark an order as completed
// userHandle.js

export const markOrderAsCompleted = (orderId) => async (dispatch) => {
    try {
        const response = await fetch(`https://backend.alamkitab.com/api/orders/${orderId}/complete`, {  // Should be 5000 or the correct backend port
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to mark the order as completed');
        }

        const data = await response.json();

        // Optionally dispatch some action to update the state
        dispatch({ type: 'ORDER_MARKED_AS_COMPLETED', payload: orderId });
        return data;
    } catch (error) {
        console.error("Error marking order as completed:", error);
    }
};

export const updateStuff = (fields, id, address) => async (dispatch) => {

    try {
        const result = await axios.put(`https://backend.alamkitab.com/${address}/${id}`, fields, {
            headers: { 'Content-Type': 'application/json' },
        });
        if (result.data.message) {
            dispatch(updateFailed(result.data.message));
        }
        else {
            dispatch(stuffUpdated());
        }

    } catch (error) {
        dispatch(getError(error.message));
    }
}

export const deleteStuff = (id, address) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.delete(`https://backend.alamkitab.com/${address}/${id}`);
        if (result.data.message) {
            dispatch(getFailed(result.data.message));
        } else {
            dispatch(getDeleteSuccess());
        }
    } catch (error) {
        dispatch(getError(error.message));
    }
}

export const updateCustomer = (fields, id) => async (dispatch) => {
    dispatch(updateCurrentUser(fields));

    const newFields = { ...fields };
    delete newFields.token;

    try {
        await axios.put(`https://backend.alamkitab.com/CustomerUpdate/${id}`, newFields, {
            headers: { 'Content-Type': 'application/json' },
        });

        dispatch(stuffUpdated());

    } catch (error) {
        // Extract relevant information from the Axios error object
        const errorMessage = error.message;
        const errorCode = error.code;

        // Dispatch an action with only serializable payload
        dispatch(getError({ message: errorMessage, code: errorCode }));
    }
}


export const getProductsbySeller = (id) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`https://backend.alamkitab.com/getSellerProducts/${id}`);
        if (result.data.message) {
            dispatch(getSellerProductsFailed(result.data.message));
        }
        else {
            dispatch(sellerProductSuccess(result.data));
        }
    } catch (error) {
        dispatch(getError(error.message));
    }
}



// Similar updates for other thunks
export const getProducts = () => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`https://backend.alamkitab.com/getProducts`);
        
        if (result?.data?.message) {
            dispatch(getProductsFailed(result?.data?.message));
        } else {
            dispatch(productSuccess(result?.data));
        }
    } catch (error) {
        const errorMessage = error.message || 'An unexpected error occurred';
        const statusCode = error.response?.status || 500;
        dispatch(getError({ message: errorMessage, status: statusCode }));
    }
};


export const getProductDetails = (id) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`https://backend.alamkitab.com/getProductDetail/${id}`);
        if (result.data.message) {
            dispatch(getProductDetailsFailed(result.data.message));
        }
        else {
            dispatch(productDetailsSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error.message));
    }
}

export const getCustomers = (address, id) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`https://backend.alamkitab.com/${address}/${id}`);
        //console.log('API Response:', result?.data); // Debugging log
        if (result.data.message) {
            dispatch(getCustomersListFailed(result.data.message));
        }
        else {
            dispatch(customersListSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error.message));
    }
}


export const getSpecificProducts = (address,id) => async (dispatch) => {
    dispatch(getRequest());
    try {
        const result = await axios.get(`https://backend.alamkitab.com/${id}/${address}`);
        if (result.data.message) {
            dispatch(getSpecificProductsFailed(result.data.message));
        }
        else {
            dispatch(specificProductSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error.error));
    }
}


export const getSearchedProducts = (address, key) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`https://backend.alamkitab.com/${address}/${key}`);
        if (result.data.message) {
            dispatch(getSearchFailed(result.data.message));
        }
        else {
            dispatch(setFilteredProducts(result.data));
        }

    } catch (error) {
        dispatch(getError(error.message));
    }
}


// import axios from 'axios';
// import {
//     authRequest,
//     authSuccess,
//     authFailed,
//     authError,
//     stuffAdded,
//     getDeleteSuccess,
//     getRequest,
//     getFailed,
//     getError,
//     productSuccess,
//     productDetailsSuccess,
//     getProductDetailsFailed,
//     getProductsFailed,
//     setFilteredProducts,
//     getSearchFailed,
//     sellerProductSuccess,
//     getSellerProductsFailed,
//     stuffUpdated,
//     updateFailed,
//     getCustomersListFailed,
//     customersListSuccess,
//     getSpecificProductsFailed,
//     specificProductSuccess,
//     updateCurrentUser,
// } from './userSlice';

// const extractErrorDetails = (error) => {
//     return {
//         message: error.response?.data?.message || error.message || 'Unknown error occurred',
//         status: error.response?.status || 500
//     };
// };

// export const authUser = (fields, role, mode) => async (dispatch) => {
//     dispatch(authRequest());

//     try {
//         const result = await axios.post(`https://backend.alamkitab.com/${role}${mode}`, fields, {
//             headers: { 'Content-Type': 'application/json' },
//         });
//         if (result.data.role) {
//             dispatch(authSuccess(result.data));
//         }
//         else {
//             dispatch(authFailed(result.data.message));
//         }
//     } catch (error) {
//         dispatch(authError(extractErrorDetails(error)));
//     }
// };

// export const addStuff = (address, fields) => async (dispatch) => {
//     dispatch(authRequest());

//     try {
//         const result = await axios.post(`https://backend.alamkitab.com/${address}`, fields, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         if (result.data.message) {
//             dispatch(authFailed(result.data.message));
//         } else {
//             dispatch(stuffAdded());
//         }
//     } catch (error) {
//         dispatch(authError(extractErrorDetails(error)));
//     }
// };

// export const updateStuff = (fields, id, address) => async (dispatch) => {
//     try {
//         const result = await axios.put(`https://backend.alamkitab.com/${address}/${id}`, fields, {
//             headers: { 'Content-Type': 'application/json' },
//         });
//         if (result.data.message) {
//             dispatch(updateFailed(result.data.message));
//         }
//         else {
//             dispatch(stuffUpdated());
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const deleteStuff = (id, address) => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.delete(`https://backend.alamkitab.com/${address}/${id}`);
//         if (result.data.message) {
//             dispatch(getFailed(result.data.message));
//         } else {
//             dispatch(getDeleteSuccess());
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const updateCustomer = (fields, id) => async (dispatch) => {
//     dispatch(updateCurrentUser(fields));

//     const newFields = { ...fields };
//     delete newFields.token;

//     try {
//         await axios.put(`https://backend.alamkitab.com/CustomerUpdate/${id}`, newFields, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         dispatch(stuffUpdated());
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getProductsbySeller = (id) => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/getSellerProducts/${id}`);
//         if (result.data.message) {
//             dispatch(getSellerProductsFailed(result.data.message));
//         }
//         else {
//             dispatch(sellerProductSuccess(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getProducts = () => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/getProducts`);
//         ;
//         if (result.data.message) {
//             dispatch(getProductsFailed(result.data.message));
//         } else {
//             dispatch(productSuccess(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getProductDetails = (id) => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/getProductDetail/${id}`);
//         if (result.data.message) {
//             dispatch(getProductDetailsFailed(result.data.message));
//         }
//         else {
//             dispatch(productDetailsSuccess(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getCustomers = (id, address) => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/${address}/${id}`);
//         if (result.data.message) {
//             dispatch(getCustomersListFailed(result.data.message));
//         }
//         else {
//             dispatch(customersListSuccess(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getSpecificProducts = (id, address) => async (dispatch) => {
//     dispatch(getRequest());
//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/${address}/${id}`);
//         if (result.data.message) {
//             dispatch(getSpecificProductsFailed(result.data.message));
//         } else {
//             dispatch(specificProductSuccess(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };

// export const getSearchedProducts = (address, key) => async (dispatch) => {
//     dispatch(getRequest());

//     try {
//         const result = await axios.get(`https://backend.alamkitab.com/${address}/${key}`);
//         if (result.data.message) {
//             dispatch(getSearchFailed(result.data.message));
//         }
//         else {
//             dispatch(setFilteredProducts(result.data));
//         }
//     } catch (error) {
//         dispatch(getError(extractErrorDetails(error)));
//     }
// };