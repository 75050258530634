// promoReducer.js
const initialPromoState = {
    appliedPromoCode: null,
};

export const promoReducer = (state = initialPromoState, action) => {
    switch (action.type) {
        case 'APPLY_PROMO_CODE':
            return {
                ...state,
                appliedPromoCode: action.payload,
            };
        default:
            return state;
    }
};
