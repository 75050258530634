import React, { useEffect, useState } from 'react';
import { Box, Button, colors, Container, styled } from '@mui/material';
import Slide from './Slide';
import './cssforstyling.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../redux/userHandle.js';
import ProductsMenu from './customer/components/ProductsMenu';
import { NewtonsCradle } from '@uiball/loaders';
import { Link } from 'react-router-dom';
import Search from './customer/components/Search.jsx';

const Home = () => {
  const dispatch = useDispatch();
  const { productData, responseProducts, error } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true); // Initial loading state
  const [showNetworkError, setShowNetworkError] = useState(false);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (responseProducts || productData || error) {
      console.log("Data fetched or error occurred"); // For debugging
      setLoading(false); // Turn off loader after fetch
    }
  }, [responseProducts, productData, error]);

  useEffect(() => {
    if (error) {
      console.error("Network error:", error); // Log errors for debugging
      const timeoutId = setTimeout(() => {
        setShowNetworkError(true);
      }, 40000);
      return () => clearTimeout(timeoutId);
    }
  }, [error]);

  return (
    <div id="top">
      <Container
        sx={{
          display: 'none',
          '@media (max-width: 600px)': {
            display: 'flex',
          },
        }}
      >
      </Container>
      <BannerBox className='BannerBox'>
        <h1 className='h1tag'>Search the books you want</h1><br />
        <Box sx={{ flexGrow: 3, display: { md: 'flex' }, }}>
          <Search /><br />
          <ProductsMenu dropName="Explore Categories" />
        </Box><br /><br />

        {/* Buttons Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            '@media (min-width: 601px)': {
              display: 'none', // Hide on larger screens
            },
          }}
        >
         
        </Box>
      </BannerBox>


      {loading ? ( // Show loader when loading
        <FullScreenLoader>
          <NewtonsCradle size={70} speed={1.4} color="black" />
        </FullScreenLoader>
      ) : showNetworkError ? ( // Show network error if network issue
        <FullScreenLoader>
          <h1>Sorry Network Issue.</h1>
        </FullScreenLoader>
      ) : error ? ( // General error fallback
        <FullScreenLoader>
          <h1>Please Wait A Second</h1>
          <NewtonsCradle size={70} speed={1.4} color="black" />
        </FullScreenLoader>
      ) : (
        <>
          {productData && productData.length > 0 ? ( // If products are available
            <Component>
              <Slide products={productData} title="Order Books" />
            </Component>
          ) : (
            <>
              <StyledContainer>No products found right now</StyledContainer>
              <StyledContainer>
                Become a seller to add products
                <Link to={"/Sellerregister"}>
                  Join
                </Link>
              </StyledContainer>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Home;

const FullScreenLoader = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
`;

const BannerBox = styled(Box)`
  background: darkgreen;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.green[500]};
  color: white;
  padding: 16px 32px;
  font-size: 18px;
  border-radius: 8px;
  &:hover {
    background-color: ${colors.green[700]};
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const Component = styled(Box)`
  display: flex;
`;
